import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from '../../../../common/src/components/Box';
import Text from '../../../../common/src/components/Text';
import Heading from '../../../../common/src/components/Heading';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';

import GlideSlide from '../../../../common/src/components/GlideCarousel/glideSlide';

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,

} from './pricing.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MONTHLY_PRICING_TABLE {
          name
          price
          priceLabel
          description
          buttonLabel
          url
          freePlan
          listItems {
            content
          }
        }
      }
    }
  `);

  const [state] = useState({
    data: Data.saasJson.MONTHLY_PRICING_TABLE,
    active: true,
  });

  const data = state.data;



  return (
    <Box {...sectionWrapper} id="pricing_section"  >
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} />
          <Heading {...secHeading} />
        </Box>
        <Box {...row}>

            <>
              {data.map((pricingTable, index) => (
                <GlideSlide key={`pricing-table-${index}`}>
                  <PricingTable
                  style={{backgroundColor:'#DEE8C3'}}
                    freePlan={pricingTable.freePlan}
                    className="pricing_table"
                  >
                    <PricingHead>
                      <Heading content={pricingTable.name} {...nameStyle} />
                      <Text
                        content={pricingTable.description}
                        {...descriptionStyle}
                      />
                    </PricingHead>
                    <PricingPrice>
                      <Text content={pricingTable.price} {...priceStyle} />
                      <Text
                        content={pricingTable.priceLabel}
                        {...priceLabelStyle}
                      />
                    </PricingPrice>
                    <PricingButton >
                      <Link to="/#trial_section">
                        {!pricingTable.freePlan ? (
                          <Button
                            title="YA ME ANIMÉ"
                            {...buttonFillStyle}
                          />
                        ) : (
                          <Button
                            title="Estoy Listo"
                            {...buttonFillStyle}
                          />
                        )}
                      </Link>
                    </PricingButton>
                    <PricingList style={{textAlign:'left',width:'fit-content', margin:'auto'}}>
                      {pricingTable.listItems.map((item, index) => (
                        <ListItem key={`pricing-table-list-${index}`}>
                          <Icon
                            icon={checkmark}
                            className="price_list_icon"
                            size={13}
                          />
                          <Text content={item.content} {...listContentStyle} />
                        </ListItem>
                      ))}
                    </PricingList>
                  </PricingTable>
                </GlideSlide>
              ))}
            </>
        </Box>
        {/*<GlideSlide key={`pricing-table-02`}>
                  <PricingTable
                  style={{backgroundColor:'#DEE8C3'}}
                    
                    className="pricing_table"
                  >
                    <PricingHead>
                      <Heading content='Plan Online personalizado 2 personas' {...nameStyle} />
                      <Text
                        content=''
                        {...descriptionStyle}
                      />
                    </PricingHead>
                    <PricingPrice>
                      <Text content='$700.00MXN' {...priceStyle} />
                      <Text
                        content='Por 2 consultas'
                        {...priceLabelStyle}
                      />
                    </PricingPrice>
                    <PricingButton >
                      <Link to="/#trial_section">
                   
                          <Button
                            title="YA NOS ANIMAMOS"
                            {...buttonFillStyle}
                          />
                       
                      </Link>
                    </PricingButton>
                    <PricingList style={{textAlign:'left',width:'fit-content', margin:'auto'}}>
       
                        <ListItem key={`pricing-table-list-01`}>
                          <Icon
                            icon={checkmark}
                            className="price_list_icon"
                            size={13}
                          />
                          <Text content='Lo mismo que el plan Online, pero con una pareja ' {...listContentStyle} />
                        </ListItem>
                      
                    </PricingList>
                  </PricingTable>
                      </GlideSlide>*/}
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
  
    as: 'section',
    pt: ['60px', '80px', '80px', '80px', '120px'],
    pb: ['20px', '20px', '20px', '80px'],
  },
  row: {
    margin:'0 20vw',
    flexBox: true,
    flexWrap: 'wrap',
    ml: 'auto',
    mr: 'auto',

  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    content: 'PRECIOS',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#c73279',
    mb: '10px',
  },
  secHeading: {
    content: 'Conoce que incluyen nuestras consultas',
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primary',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
