import styled from 'styled-components';
import {
  themeGet
} from 'styled-system';


const BannerWrapper = styled.section `
  padding-top: 230px;
  padding-bottom: 160px;

  min-height: 100vh;
  position: relative;
  overflow: hidden;

  @media (min-width:768px) {
    min-height: 40vh;
  }
  @media (min-width:1592px) {
    min-height: 100vh;
    .banner-img{
      padding-bottom:'10%'
    }
  }
  @media (max-width: 767px) {
    
    padding-top: 130px;
    padding-bottom: 20px;
    min-height: auto;
  }

  @media only screen and (max-width: 768px) {
    background: none;
    min-height: 60vh;
  }

  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      margin-left:10%;
      margin-top:10%;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

const BannerObject = styled.div `
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-right:-8%;
    margin-top:-1%;
    opacity:0.9;
  }
  .objectWrapper {
    margin-left: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 0;
      right: 0;
      .chatObject {
        position: absolute;
        top: 20px;
        left: 120px;
      }
    }
  }
`;

const DiscountLabel = styled.div `
  display: inline-block;
  border-radius: 4em;
  border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
  padding: 7px 25px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
`;

export {
  DiscountLabel,
  BannerObject
};

export default BannerWrapper;