import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Box from '../../../../common/src/components/Box';
import Text from '../../../../common/src/components/Text';
import Heading from '../../../../common/src/components/Heading';

import Card from '../../../../common/src/components/Card';
import Image from '../../../../common/src/components/Image';
import FeatureBlock from '../../../../common/src/components/FeatureBlock';
import Container from '../../../../common/src/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './visitor.style';


import ImageBg from '../../../../src/images/visitor_bg.png';

import Img from 'gatsby-image'

const query = graphql`
query {
 file(relativePath:{eq: "foto-conocenos-min.jpg"}) {
    childImageSharp{
      fluid(maxWidth:4000, quality:90){
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  
}
`

const VisitorSection = ({

  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
}) => {

    const data = useStaticQuery(query)


  return (
    <VisitorSectionWrapper style={{ width:'90%'}} id="visitorSection">
      <Container>
        <Box style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} {...textArea}>
        
          <FeatureBlock
            title={
              <Heading
                content="Conócenos"
                style={{textDecorationLine:'overline', textDecorationColor:'#c73379'}}
                {...title}
              />
            }
            description={
              <Text
                content="¡Hola! Somos Verónica y Fernanda Molina, 2 hermanas nutriólogas, 2 mentes trabajando en equipo, complementándose. Nos actualizamos constantemente; nuestros planes de alimentación son individualizados y están basados en la mejor evidencia científica en Nutrición."
                {...description}
              />
            }
            
          />
        </Box>
      </Container>
      <SectionObject style={{marginBottom:'5%'}}>
        <Card className="objectWrapper" {...imageWrapper}>
            <Image style={{zIndex:"1"}} src={ImageBg} alt="BgImage" />
            <Fade left>
        
            <Img fluid={data.file.childImageSharp.fluid} style={{opacity:'0.8',zIndex:'3',marginLeft:'-3%', marginTop:'-70%', borderRadius:'2.5%', height:"70%", width:"100%"}}  alt="BannerObject2" />
            </Fade>
        </Card>
      </SectionObject>
    </VisitorSectionWrapper>
  );
};


VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400', 
    textDecorationLine: 'underline',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
