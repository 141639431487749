import styled from 'styled-components';

const VisitorSectionWrapper = styled.section `
  min-height: 630px;
  display: flex;
  align-items: center;
  margin-bottom: 7.5vh;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-top: 50px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
  }
`;

const SectionObject = styled.div `
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 93%;
    height: auto;
  }
  
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .imageWrapper Img {
      :hover{
        cursor:pointer !important;
        opacity:0.4 !important;
        transform: scale(0.97);
        transition: all .5s ease-in-out !important;
      }
    
    .imageWrapper h3 {
      :hover {
        text-decoration: underline;
        transition: all .5s ease !important;
      }
    }

    }

    }
    
.dashboardWrapper {
      position: absolute;
      top: 2vw;
      left: 0;
    }
  }
`;


export {
  SectionObject
};

export default VisitorSectionWrapper;