import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import Box from '../../../../common/src/components/Box';
import Text from '../../../../common/src/components/Text';

import Heading from '../../../../common/src/components/Heading';
import Button from '../../../../common/src/components/Button';
import FeatureBlock from '../../../../common/src/components/FeatureBlock';
import Container from '../../../../common/src/components/UI/Container';
import Particles from '../Particle';
import {useStaticQuery, graphql, Link} from 'gatsby'
import BannerWrapper, {
  BannerObject,
} from './bannerSection.style';
import Img from 'gatsby-image'


const query = graphql`
query {
  defaultBcg: file(relativePath:{eq: "banner-forma.png"}) {
    childImageSharp{
      fluid(maxWidth:1000, quality:90){
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
}
`

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
 
}) => {
 const data = useStaticQuery(query)

  const ButtonGroup = () => (
    <Fragment>
      <Link to='/#trial_section'>
        <Button title="AGENDA TU CITA" {...btnStyle} />
      </Link>
     
    </Fragment>
  );
  return (
    <>
    <BannerWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
           
            <FeatureBlock
              class="banner-left"
              title={
                <Heading
                  content="Empecemos con tu nuevo estilo de vida saludable."
                  {...title}
                />
              }
              description={
                <Text
                  content="Cambia tu estilo de vida, por medio de una deliciosa y sana alimentación que sea sostenible para toda la vida."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
            
          </Box>
        </Box>
      </Container>
      <BannerObject >
        <div className="objectWrapper" style={{paddingTop:"5%"}} >
          
          <Img class="banner-img" style={{width:'47.5vw', height:'auto', zIndex:'0', opacity:'0.9'}}  fluid={data.defaultBcg.childImageSharp.fluid} alt="BannerObject1" />
        </div>
      </BannerObject>
    </BannerWrapper>
          </>
  );
};



BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
    
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
   
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
    float:'center'
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
