import React from 'react';
import {Link} from 'gatsby'
import PropTypes from 'prop-types';
import Button from '../../../../common/src/components/Button';
import Box from '../../../../common/src/components/Box';
import Text from '../../../../common/src/components/Text';
import Heading from '../../../../common/src/components/Heading';
import Image from '../../../../common/src/components/Image';
import Container from '../../../../common/src/components/UI/Container';
import FBMsnLogo from '../../../../src/images/facebook-messenger-logo.svg'
import WALogo from '../../../../src/images/whatsapp-logo.svg'
import { ButtonGroup } from '../saas.style';
import OldCheckout from '../../OldCheckout'
import Checkout from '../../Checkout'


const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  imageArea,
  ImageOne,
  buttonFillStyle,
  btnStyle,
  outlineBtnStyle,
}) => {
  return (
    <Box {...sectionWrapper} id="trial_section" style={{backgroundColor:'#E7BACD'}}>
      <Container >
      <br/><br/>
        <Box {...row} >
        
          <Box {...textArea}>
            <Heading
              {...title}
              style={{textDecoration:'underline', textDecorationColor:'#c73279'}}
              content="¡Comienza ya tu nuevo estilo de vida o reagenda tu cita!"
            />
            <Text
              {...description}
              content="Paga tu cita con cualquier tarjeta mediante PayPal."
            />
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <OldCheckout />
            </div>
    
          </Box>
        </Box>
        <br/><br/>
     
        <br/><br/>
        <Box {...row} style={{paddingTop:'10%'}}>
        
          <Box {...textArea}>
            <Heading
              {...title}
              content="O si te quedaste con dudas..."
            />
            <Text
              {...description}
              content="¡Contáctanos por Whatsapp o Facebook haciendo click en los íconos!"
            />
            <ButtonGroup style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center'}} className="button_group">
              <a href="https://wa.me/529992796845?text=Hola" target="_blank" rel="noopener noreferrer" alt="Link a Whatsapp">
              <Image src={WALogo}  style={{width:'15vw',height:'15vh',margin:'2% 2% 2% 2%'}} alt="Logo Whatsapp"/>
              </a>
            
             

              <a href="https://m.me/molinasnutricion/" target='_blank' rel="noopener noreferrer" alt="Link a Facebook Messenger">
              <Image src={FBMsnLogo}  style={{width:'14vw',height:'14vh',margin:'2% 2% 2% 2%'}} alt="Logo Facebook Messenger"/>
              </a>
            </ButtonGroup>
            
          </Box>
        </Box>

      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  buttonFillStyle: PropTypes.object,
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['0px', '0px', '0px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textArea: {
    width: ['90%', '90%', '80%', '43%'],
  },
  imageArea: {
    width: ['100%', '100%', '43%'],
    mb: ['35px', '35px', '40px', '40px'],
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  title: {
    fontSize: ['32px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['35px', '35px', '40px', '60px'],
  },
  ImageOne: {
    ml: 'auto',
    mr: 'auto',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
  },
};

export default TrialSection;
