import React from 'react';

import PropTypes from 'prop-types';
import Box from '../../../../common/src/components/Box';
import Text from '../../../../common/src/components/Text';
import Heading from '../../../../common/src/components/Heading';
import FeatureBlock from '../../../../common/src/components/FeatureBlock';
import Container from '../../../../common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';

import Icon1 from '../../../images/icon1.png'
import Icon2 from '../../../images/icon2bueno.png'
import Icon3 from '../../../images/icon3-diab.png'


const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {


  return (
    <FeatureSectionWrapper id="service_section">
      <Container>


        <Box className="row" {...row}>

        <Box className="col" {...col} key="01">
              <FeatureBlock
                icon={<img style={{width:'100%', height:'100%'}}  src={Icon1} alt="ícono nutrición sana" />}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={<Heading content="Control de peso y aumento de masa muscular " {...featureTitle} />}
                description={
                  <Text content="Te ayudamos a verte y sentirte mejor, a través de la mejora de tu composición corporal, que ayudará a tratar y prevenir enfermedades." {...featureDescription} />
                }
                className="saasFeature"
              />
            
            </Box>

            <Box className="col" {...col} key="02">
              <FeatureBlock
                icon={<img style={{width:'100%', height:'100%'}}  src={Icon2} alt="ícono nutrición emabarazo" />}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={<Heading content="Embarazo" {...featureTitle} />}
                description={
                  <Text content="Si estas embarazada te brindamos el mejor acompañamiento nutricional para la programación del óptimo desarrollo, metabolismo y salud de tu bebé para toda su vida." {...featureDescription} />
                }
                className="saasFeature"
              />
            
            </Box>

            <Box className="col" {...col} key="03">
              <FeatureBlock
                icon={<img style={{width:'100%', height:'100%'}}  src={Icon3} alt="ícono nutrición diabetes" />}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={<Heading content="Diabetes" {...featureTitle} />}
                description={
                  <Text content="Si vives con diabetes, podemos enseñarte a comer, elaborando un plan de alimentación personalizado, enfocado en controlar  tus niveles de glucosa en sangre, satisfaciendo tus gustos y necesidades." {...featureDescription} />
                }
                className="saasFeature"
              />
            
            </Box>





            
          
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#c73279',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '75px', '75px', '84px'],
    height: ['70px', '75px', '75px', '84px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: ['20px', '20px', '20px', '30px'],
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default FeatureSection;
