import React from "react"

import { PayPalButton } from "react-paypal-button-v2";
import Button from './../../common/src/components/Button/index';
import PropTypes from 'prop-types';
import Box from '../../common/src/components/Box';



//const stripePromise = loadStripe(process.env.STRIPE_KEY)
//
//const redirectToCheckout = async event => {
//  event.preventDefault()
//
//  const stripe = await stripePromise
//  const { error } = await stripe.redirectToCheckout({
//    items: [{ sku: process.env.SKU, quantity: 1 }],
//    successUrl: `https://molinasnutricion.com.mx/success/`,
//    cancelUrl: `https://molinasnutricion.com.mx`,
//  })
//
//  if (error) {
//    console.warn("Error:", error)
//  }
//}

const Checkout = ({button, buttonWrapper}) => {
  const paymentHandler = (details, data) => {
    console.log(details, data)
    if (typeof window !== 'undefined') {
      window.location = '/success';
    }
    return null;
  }
  return (
    <>
    <Box {...buttonWrapper} style={{display:'flex', flexDirection:'column'}}>
      <div style={{paddingRight:'0.2rem', textAlign:'center'}}>
        <p>Plan Online</p>
    <PayPalButton
              
              amount="500"
              onSuccess={paymentHandler}
              shippingPreference="NO_SHIPPING"
              options={{
                clientId: `${process.env.GATSBY_PAYPAL_KEY}`,
                disableFunding: "card",
                currency: "MXN"
              }}
          />
          </div>
          <br />
          {/*<div style={{paddingLeft:'0.2rem', textAlign:'center'}}>
            <p>Plan para 2 personas</p>
              <PayPalButton
              
              amount="700"
              onSuccess={paymentHandler}
              shippingPreference="NO_SHIPPING"
              options={{
                clientId: `${process.env.GATSBY_PAYPAL_KEY}`,
                disableFunding: "card",
                currency: "MXN"
              }}
          />
            </div>*/}
    </Box>
   </>
  )
}

Checkout.propTypes = {
  button: PropTypes.object,
  buttonWrapper: PropTypes.object,
  
};
Checkout.defaultProps = {
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  }
}
export default Checkout