import React, { Fragment } from 'react';


import PropTypes from 'prop-types';
import Text from '../../../../common/src/components/Text';
import Heading from '../../../../common/src/components/Heading';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';
import GlideCarousel from '../../../../common/src/components/GlideCarousel';
import GlideSlide from '../../../../common/src/components/GlideCarousel/glideSlide';
import TestimonialSectionWrapper, {
  TextWrapper,

  ClientName,
} from './testimonialSection.style';


const TestimonialSection = ({
  sectionSubTitle,
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
}) => {

  // Glide js options
  const glideOptions = {
    type: 'carousel',
    autoplay: 5000,
    perView: 1,
  };

  return (
    <TestimonialSectionWrapper id="testimonial_section" style={{backgroundColor:'#DEE8C3'}} >
      <Container style={{backgroundColor:'#fafbff'}}>
        <Text content="TESTIMONIOS DE PACIENTES" {...sectionSubTitle} />
        <GlideCarousel
          options={glideOptions}
          buttonWrapperStyle={btnWrapperStyle}
          nextButton={
            <Button
              icon={<i className="flaticon-next" />}
              variant="textButton"
              aria-label="next"
              {...btnStyle}
            />
          }
          prevButton={
            <Button
              icon={<i className="flaticon-left-arrow" />}
              variant="textButton"
              aria-label="prev"
              {...btnStyle}
            />
          }
        >
          <Fragment>
          <GlideSlide key='01'>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content="Pocos como ellas. He tenido la oportunidad de ser paciente de Vero y también colaboramos en unos proyectos. Siempre preocupadas por ayudar y servir." {...commentStyle} />
                    <ClientName>
                      <Heading content="Pedro Evia" {...nameStyle} />
                      <Heading
                        content=''
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>

                </Fragment>
              </GlideSlide>
          </Fragment>

          <Fragment>
          <GlideSlide key='02'>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content="He consultado con ambas y son súper profesionales y dedicadas. Siempre con planes que se ajustan a mi estilo de vida, priorizando mi salud." {...commentStyle} />
                    <ClientName>
                      <Heading content="Fide Casares" {...nameStyle} />
                      <Heading
                        content=''
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>

                </Fragment>
              </GlideSlide>
          </Fragment>

          <Fragment>
          <GlideSlide key='03'>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content="Me enseñaron a llevar un estilo de vida saludable y que no se trata de comer menos si no de comer mejor. Aprendí que el ejercicio no es mi enemigo, al contrario." {...commentStyle} />
                    <ClientName>
                      <Heading content="Juan José García" {...nameStyle} />
                      <Heading
                        content=''
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>

                </Fragment>
              </GlideSlide>
          </Fragment>

          <Fragment>
          <GlideSlide key='04'>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content="Desde el día 1 hicimos un equipo increíble, se adaptaron a cada una de mis actividades enseñándome a cómo todo era posible con organización y paciencia." {...commentStyle} />
                    <ClientName>
                      <Heading content="Jen" {...nameStyle} />
                      <Heading
                        content=''
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>

                </Fragment>
              </GlideSlide>
          </Fragment>

          <Fragment>
          <GlideSlide key='05'>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content="Cuando me diagnosticaron diabetes estaba muy asustada y no sabía qué hacer. Llegué al consultorio y ahora  llevo conmigo todo el apoyo, recetas increíbles y un mejor estilo y calidad de vida." {...commentStyle} />
                    <ClientName>
                      <Heading content="Gaby" {...nameStyle} />
                      <Heading
                        content=''
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>

                </Fragment>
              </GlideSlide>
          </Fragment>
        </GlideCarousel>
      </Container>
    </TestimonialSectionWrapper>
  );
};

// TestimonialSection style props
TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

// TestimonialSection default style
TestimonialSection.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#c73279',
    mb: '20px',
    ml: 'auto',
    mr: 'auto',
    pl: '12px',
    maxWidth: '954px',
  },
  // client comment style
  commentStyle: {
    color: '#0f2137',
    fontWeight: '400',
    fontSize: ['22px', '22px', '22px', '30px'],
    lineHeight: '1.72',
    mb: '47px',
  },
  // client name style
  nameStyle: {
    as: 'h3',
    color: '#343d48',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    
  },
  // client designation style
  designationStyle: {
    as: 'h5',
    color: 'rgba(52, 61, 72, 0.8)',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    ml: ['0', '10px'],
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: 'absolute',
    bottom: '62px',
    left: '12px',
  },
  // next / prev btn style
  btnStyle: {
    minWidth: 'auto',
    minHeight: 'auto',
    mr: '13px',
    fontSize: '16px',
    color: '#343d484d',
  },
};

export default TestimonialSection;
